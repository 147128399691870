import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Typography,
  Container,
  MenuItem,
  Grid,
  TextField,
  Divider,
  Autocomplete,
  Chip,
  Box,
  Button,
  Paper,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { useSettingsContext } from "src/components/settings";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { app, dbFS } from "src/firebase";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import dayjs from "dayjs";
import {
  useCreateSingleMessageTemplatesMutation,
  useGetTemplateQuery,
} from "src/services";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { useSelector } from "react-redux";
import { DB } from "src/auth/FirebaseContext";
import { dispatch } from "src/redux/store";
import { updateTrial } from "src/redux/slices/trialSlice";
import Iconify from "src/components/iconify/Iconify";
import Preview from "src/components/MessageTemplate/Preview";
import NewCustomerModel from "../Customer/NewCustomerModel";
import NewClient from "../Voters/NewClient";

interface FormValuesProps extends Omit<any, ""> {}

function CreateCategory() {
  const { businessID, credit, ...trialData } = useSelector(
    (state: any) => state.trial
  );
  const [searchPrams] = useSearchParams();
  const templateURLName = searchPrams.get("name");

  const [singleTemplates, setSingleTemplates] = useState<any>();
  const [singleTemplat, setSingleTemplat] = useState<any>();
  const { data, error } = useGetTemplateQuery({
    limit: 6000
  });
  const [textFields, setTextFields] = useState<string[]>([]);
  const [headerVariable, setHeaderVariable] = useState<string>();
  const [bodyError, setBodyError] = useState<string>();
  const [templateError, setTemplateError] = useState<string>();
  const [url, setUrl] = useState<any>(null);
  const [modelOpen, setModelOpen] = useState<boolean>(false);
  const handleModelOpen = () => {
    setModelOpen(!modelOpen);
  };

  const handleTextFieldChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValues = [...textFields];
    newValues[index] = event.target.value;
    setTextFields(newValues);
  };

  const getType = (components: any, type: string) => {
    const filteredComponents = components.filter((component: any) => {
      return component.type === type;
    });
    return filteredComponents[0];
  };

  const MessageTemplateList = useMemo(() => {
    const res = data?.data.data.map((data: any, index: number) => {
      const components = data?.components;
      const product = {
        id: data.id,
        category: data.category,
        name: data?.name,
        status: data.status,
        language: data.language,
        bodyType: getType(components, "BODY")?.type,
        headerType: getType(components, "HEADER")?.type,
        headerFormat: getType(components, "HEADER")?.format,
        bodyFormat: getType(components, "BODY")?.example
          ? getType(components, "BODY")?.example
          : "",
        headerVariable: getType(components, "HEADER")?.example
          ? getType(components, "HEADER")?.example
          : "",
        // footer: getType(components, "FOOTER")?.text,
        components: data?.components.map((data: any) => data),
        // buttons: getType(components, "BUTTONS").buttons,
      };
      return product;
    });
    return res;
  }, [data]);

  const [CategoryList] = useCollection(
    collection(getFirestore(app), "category"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [categoryList, setCategoryList] = useState<any>([]);

  useEffect(() => {
    const tempData: { id: number }[] = [];
    if (CategoryList) {
      let index = 1;
      CategoryList?.forEach((doc) => {
        const childData = doc.data();
        tempData.push({ ...childData, id: index++ });
      });
      setCategoryList(tempData);
    }
  }, [CategoryList]);

  const collectionRef = collection(dbFS, "tag");

  const q = query(collectionRef, where("businessID", "==", businessID));

  const [TagList] = useCollection(q, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [tagList, setTagList] = useState<any>();

  useEffect(() => {
    const tempData: { id: number }[] = [];
    if (TagList) {
      let index = 1;
      TagList?.forEach((doc) => {
        const childData = doc.data();
        tempData.push({ ...childData, id: index++ });
      });
      console.log(...tempData);

      setTagList(tempData);
    }
  }, [TagList]);




  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const NewProductSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Must be 3 character"),
  });

  const defaultValues = {
    selectContact: "",
    contact: " ",
    ScheduleBroadcast: 0,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
    control,
    watch,
  } = methods;

  const values = watch();
  //client with filter
  const collectionRefs = collection(dbFS, "client");

  const qs = query(collectionRefs, where("businessID", "==", businessID),where("status", "==", "active"));

  const [ClientList] = useCollection(qs, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [clientListCategory, setClinetListCategory] = useState<any>([]);
  const [clientListTag, setClinetListTag] = useState<any>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [initialContacts, setInitialContact] = useState([]);
  const [mediaLoading, setMediaLoading] = useState<boolean>();

  useEffect(() => {
    const tempData: { id: number }[] = [];
    if (ClientList) {
      let index = 1;
      ClientList?.forEach((doc) => {
        const childData = doc.data();
        tempData.push({ ...childData, id: index++ });
      });
      setContacts(tempData);

      const filteredCategories = tempData.filter(
        (client: any) => client.category === values.category
      );
      setClinetListCategory(filteredCategories);
    }
  }, [ClientList, values.category, values.tag]);

  const initiallySelectedTags = clientListTag.map(
    (option: any) => option.phone
  );

  const [selectedTags, setSelectedTags] = useState(initiallySelectedTags);
  const inputRef = useRef<HTMLInputElement>(null);

  //first time run for contacts all
  useEffect(() => {
    const tempData: any = [];
    if (ClientList) {
      let index = 1;
      ClientList?.forEach((doc) => {
        const childData = doc.data();
        tempData.push({ ...childData, id: index++ });
      });
      // setContacts(tempData);

      const filteredContacts = tempData.filter((contact: any) =>
        contact.tag.includes("all")
      );
      const initiallySelectedTags = filteredContacts.map(
        (option: any) => option.phone
      );
      setSelectedTags(initiallySelectedTags);

      // setClinetListCategory(filteredCategories);
    }
  }, [ClientList]);

  useEffect(() => {
    setBodyError("");
  }, [selectedTags]);

  // Update the state if tagList changes
  useEffect(() => {
    if (values.tag) {
      const selectedTaggedContact = contacts
        .filter((contact) => contact.tag.includes(values.tag))
        .map((contact) => contact.phone);
      setSelectedTags(selectedTaggedContact);
    }
  }, [values.tag]);

  const [createBroadcats, { error: errorMessagfe, isLoading }] =
    useCreateSingleMessageTemplatesMutation();

  const { uid } = useSelector((state: any) => state.trial);
  const handleMedia = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setMediaLoading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `/whatsapp/media/${uid}` + file.name);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          setMediaLoading(false);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setUrl(downloadURL);
            setMediaLoading(false);
          });
        }
      );
    } else {
      enqueueSnackbar("Please Uplaod media file", { variant: "error" });
      console.log("No file selected.");
      setMediaLoading(false);
    }
  };

  useEffect(() => {
    if (templateURLName) {
      setSingleTemplates(templateURLName);
      setValue("template", templateURLName);
      console.log("I am here");
    }
  }, [templateURLName]);

  useEffect(() => {
    const SingleTemplate = MessageTemplateList?.filter(
      (template: any) => template.name === singleTemplates
    );
    const temp = SingleTemplate ? SingleTemplate[0] : {};
    setSingleTemplat(temp);
    setTemplateError("");
  }, [MessageTemplateList, singleTemplates]);
  //variable field

  useEffect(() => {
    setTextFields([]);
    const count = singleTemplat?.bodyFormat?.body_text?.reduce(
      (acc: any, curr: any) => acc + curr.length,
      0
    );
    const fields: string[] = [];
    for (let i = 0; i < count; i++) {
      fields.push(""); // Add an empty string for each text field
    }
    setTextFields(fields);
  }, [singleTemplat?.bodyFormat]);

  const handleChange = (e: any) => {
    setSingleTemplates(e.target.value);
  };

  const [variableError, setVariablesError] = useState("");

  useEffect(() => {
    setVariablesError("");
  }, [textFields]);

  useEffect(() => {
    if (singleTemplat?.name) {
      setValue(
        "name",
        singleTemplat?.name + "_" + new Date().toLocaleDateString()
      );
    }
  }, [singleTemplat]);

  const onSubmit = async (data: FormValuesProps) => {
    if (!singleTemplat) {
      setTemplateError("Please select Template!");
      return;
    }

    if (!selectedTags.length) {
      setBodyError("Please select contacts!");
      return;
    }

    const hasEmptyVariable = textFields.some(
      (variable: any) => variable === ""
    );

    if (textFields.length && hasEmptyVariable) {
      setVariablesError("Please enter all the variables");
      return;
    }

    if (singleTemplat.headerFormat === "IMAGE" && !url) {
      enqueueSnackbar("Failed to upload media please upload media file!", {
        variant: "error",
      });
      return;
    }

    console.log(data);
    const tem = () => {
      const components = [body(), header(), headerVariableBody()].filter(
        (component) => component
      );

      return {
        name: singleTemplat.name,
        language: {
          code: singleTemplat.language,
        },
        components: components,
      };
    };

    const headerVariableBody = () => {
      if (headerVariable) {
        return {
          type: "header",
          parameters: [
            {
              type: "text",
              text: headerVariable,
            },
          ],
        };
      }
    };

    const header = () => {
      if (singleTemplat.headerFormat === "IMAGE") {
        return {
          type: "header",
          parameters: [
            {
              type: "image",
              image: {
                link: url,
              },
            },
          ],
        };
      } else if (singleTemplat.headerFormat === "VIDEO") {
        return {
          type: "header",
          parameters: [
            {
              type: "video",
              video: {
                link: url,
              },
            },
          ],
        };
      } else if (singleTemplat.headerFormat === "DOCUMENT") {
        return {
          type: "header",
          parameters: [
            {
              type: "document",
              document: {
                link: url,
              },
            },
          ],
        };
      }
    };
    const bodyRequest = textFields.map((textField: string) => {
      return {
        type: "text",
        text: textField,
      };
    });

    const body = () => {
      if (singleTemplat.bodyFormat && textFields.length) {
        return {
          type: "body",
          parameters: bodyRequest,
        };
      }
    };

    try {
      let templateData = {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: selectedTags,
        type: "template",
        template: tem(),
        origina_template: singleTemplat,
        businessID: businessID,
      };
      if (templateData.to.length > credit) {
        return enqueueSnackbar(`you don't have sufficient credit messages`, {
          variant: "error",
        });
      }
      console.log(templateData);
      await createBroadcats(templateData)
        .unwrap()
        .then(async (r: any) => {
          enqueueSnackbar(`${r?.data.send} messeges sent`);
          enqueueSnackbar(`${r?.data.failed} messeges failed`, {
            variant: "error",
          });
          addDoc(collection(DB, "campaign"), {
            name: data.name,
            sendMessages: r?.data.send,
            failedMessages: r?.data.failed,
            status: "sent",
            template: singleTemplat.name,
            businessID: businessID,
          });
          dispatch(
            updateTrial({
              businessID: businessID,
              credit: credit - Number(r.data.send),
              ...trialData,
            })
          );
          const businessRef = doc(collection(DB, "business"), businessID);
          await setDoc(
            businessRef,
            {
              wallet: credit - Number(r.data.send),
            },
            { merge: true }
          );

          navigate(PATH_DASHBOARD.broadcast.list);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Broadcast</title>
      </Helmet>
      <Stack
        direction="row"
        justifyContent="space-between"
        display="flex"
        p={5}
      >
        <Typography variant="h3" component="h1" paragraph>
          Create Broadcast
        </Typography>
      </Stack>
      {/* {
        mediaLoading ?<>
        <Stack display="flex" direction="column" alignItems="center" justifyContent="center"> 
        <CircularProgress size={50} sx={{mt:10}}   />
          <Typography  variant="h3">
            Wait while Uploading media...
          </Typography>
        </Stack>
        
      </> :  */}
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={{ xs: 4 }} display="flex">
          <Grid item md={8} xs={12}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack display="flex" direction="column" spacing={2}>
              <Autocomplete
                  id="template"
                  sx={{ marginBottom: 2 }}
                  options={MessageTemplateList ?? []}
                  placeholder="Select Category"
                  autoHighlight
                  getOptionLabel={(option: any) => option?.name}
                  defaultValue={{ name: templateURLName ?? "Select template" }}
                  onSelect={(e) => {
                    handleChange(e);
                  }}
                  // defaultValue={categoryList[]}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <RHFTextField
                      // ref={category}
                      name="template"
                      label={"Select Template"}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                      <Typography variant="body2" color="error">
                  {templateError}
                </Typography>
                <RHFTextField
                  autoComplete="off" 
                  name="name"
                  label="Broadcast Name"
                  id="name"
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      fontWeight: "bold",
                    },
                  }}
                  placeholder="Enter Broadcast name..."
                />
                
          

                {singleTemplat?.headerFormat === "TEXT" &&
                singleTemplat?.headerVariable ? (
                  <RHFTextField
                    name="headerVariable"
                    type="text"
                    value={headerVariable}
                    placeholder="Enter Header Variable Here"
                    onChange={(e: any) => setHeaderVariable(e.target.value)}
                  />
                ) : (
                  ""
                )}
                {textFields.map((value, index) => (
                  <Stack direction="row" spacing={1} padding={1}>
                    <h4>{index + 1}.</h4>
                    <RHFTextField
                      name=""
                      key={index}
                      type="text"
                      value={value}
                      placeholder="Enter Body Variable Here"
                      onChange={(e: any) => handleTextFieldChange(index, e)}
                    />
                  </Stack>
                ))}
                <Typography variant="body2" color="error">
                  {variableError}
                </Typography>
                {singleTemplat?.headerFormat === "IMAGE" ||
                singleTemplat?.headerFormat === "VIDEO" ||
                singleTemplat?.headerFormat === "DOCUMENT" ? (
                  <Stack direction="column" spacing={1} padding={2} pt={0}>
                    <Typography mb={2}>
                      Chose Media that you want to send:
                    </Typography>
                    <Box>
                      <input
                        name="image"
                        type="file"
                        onChange={(e: any) => {
                          handleMedia(e);
                        }}
                        style={{ border: "1px solid grey" }}
                      />
                      {mediaLoading ? (
                        <Typography variant="h5">
                          Wait while Uploading media...
                        </Typography>
                      ) : null}
                    </Box>
                  </Stack>
                ) : null}
                <Stack direction={"row"} spacing={1}>
                  <RHFSelect
                    name="tag"
                    label="Select Contact based on a group"
                    defaultValue={"all"}
                  >
                    {tagList?.[0]?.tag?.map((data: any, index: number) => {
                      return (
                        <MenuItem value={data || 0} key={index}>
                          {data}
                        </MenuItem>
                      );
                    })}
                  </RHFSelect>
                  <Button
                    sx={{ height: 52 }}
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      setModelOpen(true);
                    }}
                    startIcon={<Iconify icon={"eva:plus-fill"} />}
                  >
                    Add Contacts
                  </Button>
                </Stack>
                <Controller
                  name="contact"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      value={selectedTags}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => {
                        setSelectedTags(newValue);
                        field.onChange(newValue);
                      }}
                      options={contacts.map((option: any) => option.phone)}
                      renderTags={(value, getTagProps) =>
                        value.map((option: any, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={index}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          ref={inputRef}
                          onChange={(e: any) => {
                            if (e.target.value.length === 12) {
                              // Automatically trigger 'Enter' key event when input reaches 12 digits
                              setTimeout(() => {
                                const event = new KeyboardEvent("keydown", {
                                  key: "Enter",
                                  keyCode: 13,
                                  which: 13,
                                  bubbles: true,
                                  cancelable: false,
                                });
                                if (inputRef.current) {
                                  inputRef.current.dispatchEvent(event);
                                }
                              }, 100); // Delay dispatching the Enter key event by 100 milliseconds (adjust as needed)
                            }
                          }}
                          label="Enter Contacts"
                          {...params}
                        />
                      )}
                    />
                  )}
                />
                <Typography variant="body2" color="error">
                  {bodyError}
                </Typography>

                <Grid item lg={12} md={12} xs={12} sx={{ textAlign: "right" }}>
                  <Stack
                    display="flex"
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <LoadingButton
                      loading={isLoading || mediaLoading}
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mr: 2 }}
                    >
                      {"Create Broadcast"}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Stack>
            </FormProvider>
          </Grid>
          <NewCustomerModel openModel={modelOpen} handleClose={handleModelOpen}>
            <NewClient modelClose={handleModelOpen} />
          </NewCustomerModel>
          <Divider />
          <Grid item xs={12} md={4}>
            <Paper sx={{ padding: 3, backgroundColor: "#F8F3ED" }}>
              <Preview
                props={singleTemplat}
                img={url}
                headerVariable={headerVariable}
                bodyVariableData={textFields}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CreateCategory;
