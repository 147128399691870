import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import trialReducer from "./slices/trialSlice";
import { baseApi } from "src/services/base";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const trialPersistConfig = {
  key: "trial",
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  trial:    persistReducer(trialPersistConfig, trialReducer),
  [baseApi.reducerPath]: baseApi.reducer,
});

export { rootPersistConfig, rootReducer };
