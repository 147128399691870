import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  Page404,
  Dashboard,
  Categories,
  LoginPage,
  Products,
  NewProduct,
  NewBrand,
  UserList,
  NewUser,
  Blog,
  AudienceList,
  NewAudience,
  Role,
  CreateBlog,
  CategoryView,
  Orderlist,
  OrderView,
  ProductView,
  BrandView,
  Brand,
  Chat,
  RegisterPage,
  NewTemplaate,
  Setting,
  Wallet,
  NewCustomer,
  Forget,
} from "./elements";
import CreateCategory from "src/pages/Campaign/CreateCampaign";
import CustomerList from "src/pages/Customer/CustomerList";
import { PATH_AUTH } from "./paths";
import Login from "src/sections/auth/LoginWithEmail";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AUTH.login} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        // {
        //   path: "email",
        //   element: (
        //     <GuestGuard>
        //       <Login />
        //     </GuestGuard>
        //   ),
        // },
        // {
        //   path: "forget",
        //   element: (
        //     <GuestGuard>
        //       <Forget />
        //     </GuestGuard>
        //   ),
        // },
        // {
        //   path: "register",
        //   element: (
        //     <GuestGuard>
        //       <RegisterPage />
        //     </GuestGuard>
        //   ),
        // },
      ],
    },

    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <Dashboard /> },

        {
          path: "broadcast",
          children: [
            {
              element: <Navigate to="/dashboard/campaign/list" replace />,
              index: true,
            },
            { path: "list", element: <Categories /> },
            { path: "new", element: <CreateCategory /> },
            { path: ":id", element: <CategoryView /> },
          ],
        },
        {
          path: "products",
          children: [
            {
              element: <Navigate to="/dashboard/products/list" replace />,
              index: true,
            },
            { path: "list", element: <Products /> },
            { path: "new", element: <NewProduct /> },
            { path: ":id", element: <ProductView /> },
          ],
        },
        {
          path: "chat",
          children: [
            {
              element: <Navigate to="/dashboard/chat/list" replace />,
              index: true,
            },
            { path: "list", element: <Chat /> },
            { path: "new", element: <NewProduct /> },
            { path: ":id", element: <Chat /> },
          ],
        },
        {
          path: "template",
          children: [
            {
              element: <Navigate to="/dashboard/template/list" replace />,
              index: true,
            },
            { path: "list", element: <Brand /> },
            { path: "new", element: <NewBrand /> },
            { path: "create", element: <NewTemplaate /> },
            { path: ":id", element: <BrandView /> },
          ],
        },
        {
          path: "contact",
          children: [
            {
              element: <Navigate to="/dashboard/user/list" replace />,
              index: true,
            },
            { path: "list", element: <UserList /> },
            { path: "new", element: <NewUser /> },
          ],
        },
        {
          path: "wallet",
          element: <Wallet />,
        },
        {
          path: "wallet/:id",
          element: <Wallet />,
        },
        {
          path: "audienceType",
          children: [
            {
              element: <Navigate to="/dashboard/audienceType/list" replace />,
              index: true,
            },
            { path: "list", element: <AudienceList /> },
            { path: "new", element: <NewAudience /> },
          ],
        },
        {
          path: "user",
          children: [
            // {
            //   element: <Navigate to="/dashboard/customer/list" replace />,
            //   index: true,
            // },
            // { path: "list", element: <CustomerList /> },
            { path: "new", element: <NewCustomer /> },
          ],
        },
        {
          path: "order",
          children: [
            {
              element: <Navigate to="/dashboard/order/list" replace />,
              index: true,
            },
            { path: "list", element: <Orderlist /> },
            { path: ":id", element: <OrderView /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/list" replace />,
              index: true,
            },
            { path: "list", element: <Blog /> },
            { path: "new", element: <CreateBlog /> },
          ],
        },
        {
          path: "role",
          children: [
            {
              element: <Navigate to="/dashboard/role/list" replace />,
              index: true,
            },
            { path: "list", element: <Role /> },
          ],
        },
        {
          path: "setting",
          element: <Setting />,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
