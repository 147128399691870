import { baseApi } from '../base';

const templateApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createTemplate: build.mutation<any, any>({
      query: (data: any) => {
        return {
          method: 'POST',
          url: `/template`,
          body: data,
        };
      },
      invalidatesTags: ["template"],
    }),
    getTemplate: build.query<any, any>({
      query: ({limit}) => ({
        method: "GET",
        url: `/templates?limit=${limit}`,
      }),
      providesTags: ["template"],
    }),
    getTemplateByIdorName: build.query<any, any>({
      query: (data: any) => {
        console.log(data);
        return {
          method: 'GET',
          url: `/templatebyidorname`,
          body: data,
        };
      },
      
  })
  }),
});

export const { useCreateTemplateMutation , useGetTemplateQuery, useLazyGetTemplateByIdorNameQuery} = templateApi;
