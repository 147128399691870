import { baseApi } from "../base";

const templateApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createUser: build.mutation<any, any>({
      query: (data: any) => {
        return {
          method: "POST",
          url: `/user`,
          body: data,
        };
      },
      invalidatesTags: ["user"],
    }),
    updateUser: build.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          method: "PUT",
          url: `/user/${id}`,
          body: data,
        };
      },
      invalidatesTags: ["user"],
    }),
  }),
});

export const { useCreateUserMutation, useUpdateUserMutation } = templateApi;
