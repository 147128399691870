// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const ENVIRONMENT_PRODUCTION = true;


export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const TRIAL_ID = "M3EXFZht7FWAHCsax1Ta"
export const API_VERSION = "v18.0";
export const FIREBASE_API = ENVIRONMENT_PRODUCTION ? {
    apiKey: "AIzaSyBmqATfvc8AOVdwBDtvRvc95dSY8S-qIao",
    authDomain: "whatsapp-politics.firebaseapp.com",
    projectId: "whatsapp-politics",
    storageBucket: "whatsapp-politics.appspot.com",
    messagingSenderId: "925901538154",
    appId: "1:925901538154:web:0f562a4ae55bb3a4949b13",
    measurementId: "G-WMX18ETEZC"
  } : {
  apiKey: "AIzaSyC8-5PVa0IWCey3nhr8M8_MGy3yQzTzfI8",
  authDomain: "wa-messaging-staging.firebaseapp.com",
  projectId: "wa-messaging-staging",
  storageBucket: "wa-messaging-staging.appspot.com",
  messagingSenderId: "825238701360",
  appId: "1:825238701360:web:dfdacdda268ed209a1f4f5"
};
export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.app;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_LARGE: 320,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
