import { baseApi } from "../base";
import { createPayment } from "./type";

const paymentApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createPayment: build.query<createPayment, any>({
      query: ({ phone, price, businessID, amount}) => {
        return {
          method: "GET",
          url: `/payment?amount=${amount}&phone=${phone}&businessID=${businessID}&price=${price}`,
        };
      },
     
    }),
    getPayment: build.query<any, any>({
      query: ({ id, orderID }) => ({
        method: "GET",
        url: `/payment/${id}/${orderID}`,
      }),
      providesTags: ["payment"],
    }),
  }),
});

export const { useLazyCreatePaymentQuery, useLazyGetPaymentQuery } = paymentApi;
