// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root:'/login',
  login: '/login',
  // email:'/email',
  // forget:'/forget',
  // register: '/register'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/app'),
  three: path(ROOTS_DASHBOARD, '/three'),
  setting: path(ROOTS_DASHBOARD, '/setting'),
  wallet: path(ROOTS_DASHBOARD, '/wallet'),
  products: {
    root: path(ROOTS_DASHBOARD, '/products/list'),
    list: path(ROOTS_DASHBOARD, '/products/list'),
    new: path(ROOTS_DASHBOARD, '/products/new'),
    view: (id: string)=> path(ROOTS_DASHBOARD, `/products/${id}`)
  },
  broadcast: {
    root: path(ROOTS_DASHBOARD, '/broadcast/list'),
    list: path(ROOTS_DASHBOARD, '/broadcast/list'),
    new: path(ROOTS_DASHBOARD, '/broadcast/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/broadcast/${id}`),
    
  },
  template: {
    root: path(ROOTS_DASHBOARD, '/template/list'),
    list: path(ROOTS_DASHBOARD, '/template/list'),
    new: path(ROOTS_DASHBOARD, '/template/new'),
    create:path(ROOTS_DASHBOARD, '/template/create'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/template/${id}`),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat/list'),
    list: path(ROOTS_DASHBOARD, '/chat/list'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/chat/${id}`),
  },
  user: {
    // root: path(ROOTS_DASHBOARD, '/user/list'),
    // list: path(ROOTS_DASHBOARD, '/user/list'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
  },
  audienceType: {
    root: path(ROOTS_DASHBOARD, '/AudienceType/list'),
    list: path(ROOTS_DASHBOARD, '/AudienceType/list'),
    new: path(ROOTS_DASHBOARD, '/AudienceType/new'),
  },
  contact: {
    root: path(ROOTS_DASHBOARD, '/contact/list'),
    list: path(ROOTS_DASHBOARD, '/contact/list'),
    new: path(ROOTS_DASHBOARD, '/contact/new'),
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order/list'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    new: path(ROOTS_DASHBOARD, '/order/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/order/${id}`),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog/list'),
    list: path(ROOTS_DASHBOARD, '/blog/list'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
  },
  role: {
    root: path(ROOTS_DASHBOARD, '/role/list'),
    list: path(ROOTS_DASHBOARD, '/role/list'),
  },
  // setting:{
  //   root: path(ROOTS_DASHBOARD, '/setting/list'),
  // }
};
