import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc, setDoc,
  onSnapshot
} from "firebase/firestore";
// config
import { FIREBASE_API } from "../config-global";
//
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  FirebaseContextType,
} from "./types";
import { TRIAL_ID } from "../config-global";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

export enum Types {
  INITIAL = "INITIAL",
}

type Payload = {
  [Types.INITIAL]: {
    isLoginSuccess: any;
    isAuthenticated: boolean;
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  isLoginSuccess: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isLoginSuccess: action.payload.isLoginSuccess,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();

const GITHUB_PROVIDER = new GithubAuthProvider();

const TWITTER_PROVIDER = new TwitterAuthProvider();

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const initialize = useCallback(() => {

  useEffect(() => {
    onAuthStateChanged(AUTH, async (user) => {
      if (user) {
        onSnapshot(doc(DB, "users", user.uid), (doc): void => {
          const profile = doc.data();
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              isLoginSuccess: false,
              user: {
                ...profile,
              },
            },
          });
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            isLoginSuccess: false,
            user: null,
          },
        });
      }
    });
  }, [dispatch]);

  // }, []);

  // useEffect(() => {
  //   initialize();
  // }, [initialize]);

  // LOGIN
  const login = useCallback((email: string, password: string) => {
    return signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(() => {
    signInWithPopup(AUTH, GOOGLE_PROVIDER);
  }, []);

  const loginWithGithub = useCallback(() => {
    signInWithPopup(AUTH, GITHUB_PROVIDER);
  }, []);

  const loginWithTwitter = useCallback(() => {
    signInWithPopup(AUTH, TWITTER_PROVIDER);
  }, []);

  // REGISTER

  const register = useCallback(
    (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      businessID?: string
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await createUserWithEmailAndPassword(
            AUTH,
            email,
            password
          );

          const userRef = doc(collection(DB, "users"), res.user?.uid);
          await setDoc(userRef, {
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
            role: "admin",
            status: "active",
            businessID: "",
            plan_id: TRIAL_ID,
            timestamp: Math.floor((new Date().getTime() / 1000) as any),
          });
          await signOut(AUTH);
          await sendEmailVerification(res?.user);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    signOut(AUTH);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isLoginSuccess: state.isLoginSuccess,
      user: state.user,
      method: "firebase",
      login,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isLoginSuccess,
      state.isInitialized,
      state.user,
      login,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      register,
      logout,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
