import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENVIRONMENT_PRODUCTION } from 'src/config-global';
// import { APP_ENV } from 'src/config';
// export const Mode: any = useSelector(state => state.mode)
// console.log(Mode)



export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: ENVIRONMENT_PRODUCTION ? 'https://us-central1-whatsapp-politics.cloudfunctions.net/api' : 'https://us-central1-wa-messaging-staging.cloudfunctions.net/api',
    prepareHeaders: (headers, { getState }) => {
      const token = 'EAADK1MbNgFUBOxTPRWx4P4yylzAQgF2PPaCcQK3AMaWynrBTsrBMYRcEppCRXiQ9WLsmaM9r84rpcs4fyQbnrfyHUurhpDhiR8qpmyFWWiPOtgnzjsMdp9q9GmuxDe8sMGmE5odXWivjScNOafXsy06A1jaZA7okk2hEejklW6iQpKCKYRnY0HaoDfiBpCXDp4E0UI1sFZAVx2ZCaQZD'
      //   (getState() as RootState).user;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    "template",
    "user",
    "payment"

  ],
});