import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import img from "../../pages/MessageTemplate/Play-Button.png";
import img2 from "../../pages/MessageTemplate/Images-icon.png";
import img3 from "../../pages/MessageTemplate/Doc-Icon.png";

export function MediaContent({
  props,
  image,
  mediaType,
  header,
  headerVariable,
  previewImage
}: any) {
  console.log(previewImage, "imgagessssssss");
  
  return (
    <div>
      {props?.headertype === 1 || props?.headerFormat === "TEXT" ? (
        <Box>
          <Typography
            variant="h6"
            color="black"
            sx={{ p: 1, wordWrap: "break-word" }}
          > {
            props?.headerText
          }
            {header?.replace("{{1}}", headerVariable ? headerVariable : "{{1}}")}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ p: 1.5 }}>
          {mediaType === "image" ? (
            <Box
              component={"img"}
              src={previewImage || image  || img2  }
              sx={{ borderRadius: 0.5 }}
            />
          ) : (
            <Box>
              {mediaType === "video" ? (
                <Box
                  component={"video"}
                  src={img2}
                  sx={{ borderRadius: 0.5 }}
                  width={220}
                />
              ) : (
                <Box>
                  {mediaType === "document" ? (
                    <Box
                      component={"img"}
                      src={img3}
                      sx={{ borderRadius: 0.5 }}
                    />
                  ) : (
                    <Box>
                      {mediaType === "document" ? <Box>Location</Box> : <></>}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </div>
  );
}

const Preview = (props?: any) => {
 console.log(props);
 
  
  const [values, setValues] = useState<any>();
  useEffect(() => {
    setValues(props?.props);
  }, [props]);
  
  const [bodyData, setBodyData] = useState('')

  function replacePlaceholders(inputString:string, variables:any) {
    // Iterate through the variables and replace the placeholders
    for (const key in variables) {
      const placeholder = `{{${Number(key) + 1}}}`;
      // Replace all occurrences of the placeholder in the inputString
      inputString = inputString?.replace(placeholder, variables?.[key]); // Assuming you want to replace with the first
    }
    return inputString;
  }
  
  
  useEffect(() => {
    const findBody = (valueToFind = "BODY") => {
      return props?.props?.components?.find((obj: any) => obj.type === valueToFind);
    };
     const resultString = replacePlaceholders(findBody()?.text, props?.bodyVariableData);
     setBodyData(resultString);
  }, [props?.bodyVariableData, props?.props?.components, values?.components])
  
  

  if (values?.components) {
    const findButton = (valueToFind = "BUTTONS") => {
      return values?.components?.find((obj: any) => obj.type === valueToFind);
    };
    // const findBody = (valueToFind = "BODY") => {
    //   return values.components?.find((obj: any) => obj.type === valueToFind);
    // };
    const findHeader = (valueToFind = "HEADER") => {
      return values?.components?.find((obj: any) => obj.type === valueToFind);
    };
   
    return (
      <div>
        <Typography>Preview</Typography>
        <Card variant="elevation" sx={{ borderRadius: 0.8 }}>
          <MediaContent
            props={values}
            header={findHeader()?.text}
            previewImage={props.img || img2}
            mediaType={values?.headerFormat?.toLowerCase()}
            headerVariable={props?.headerVariable}
          />
          <CardContent
            sx={{
              mt: -4,
            }}
          >
            <Typography
              variant="body2"
              color="textprimary"
              sx={{ ml: -1.5, wordWrap: "break-word" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: bodyData?.replace(/\\n\\n/g, "<br /><br />")
                    .replace(/\\n/g, "<br />")
                    .replace(/\*(.*?)\*/g, "<strong>$1</strong>")
                    .replace(/_(.*?)_/g, "<em>$1</em>")
                    .replace(/~(.*?)~/g, "<s>$1</s>")
                    .replace(/```(.*?)```/g, "<code>$1</code>"),
                }}
              />
            </Typography>
          </CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ ml: 1, wordWrap: "break-word" }}
          >
            {values?.footerText}
          </Typography>
          <Divider />
          <Stack direction={{ xs: "row", md: "column" }} spacing={0.3}>
            {findButton()?.buttons?.map((button: any, index: any) => {
              return (
                <>
                  <Button
                    key={index}
                    variant="text"
                    color="secondary"
                    fullWidth
                    sx={{ fontSize: 12 }}
                  >
                    {button?.text}
                  </Button>
                  <Divider />
                </>
              );
            })}
          </Stack>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Typography>Preview</Typography>
      <Card variant="elevation" sx={{ borderRadius: 0.8 }}>
        <MediaContent
          props={values}
          previewImage={props?.img}
          mediaType={props?.mediaType}
        />
        <CardContent>
          <Typography
            variant="body2"
            color="textprimary"
            sx={{ ml: -1, mt: -5, wordWrap: "break-word" }}
          >
          <div
                dangerouslySetInnerHTML={{
                  __html: props?.body?.replace(/\\n\\n/g, "<br /><br />")
                    .replace(/\\n/g, "<br />")
                    .replace(/\*(.*?)\*/g, "<strong>$1</strong>")
                    .replace(/_(.*?)_/g, "<em>$1</em>")
                    .replace(/~(.*?)~/g, "<s>$1</s>")
                    .replace(/```(.*?)```/g, "<code>$1</code>"),
                }}
              />
          </Typography>
        </CardContent>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ ml: 1, wordWrap: "break-word" }}
        >
          {values?.footerText}
        </Typography>
        <Divider />
        <Stack direction={{ xs: "row", md: "column" }} spacing={0.3}>
          {props.dynamicFields?.map((button: any, index: any) => {
            return (
              <>
                <Button
                  key={index}
                  variant="text"
                  color="secondary"
                  fullWidth
                  sx={{ fontSize: 12 }}
                >
                  {button?.buttonText}
                </Button>
                <Divider />
              </>
            );
          })}
        </Stack>
      </Card>
    </div>
  );
};

export default Preview;
