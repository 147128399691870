
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/auth'
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { ENVIRONMENT_PRODUCTION } from "./config-global";
const firebaseConfig = ENVIRONMENT_PRODUCTION ? {
    apiKey: "AIzaSyBmqATfvc8AOVdwBDtvRvc95dSY8S-qIao",
    authDomain: "whatsapp-politics.firebaseapp.com",
    projectId: "whatsapp-politics",
    storageBucket: "whatsapp-politics.appspot.com",
    messagingSenderId: "925901538154",
    appId: "1:925901538154:web:0f562a4ae55bb3a4949b13",
    measurementId: "G-WMX18ETEZC"
  } : {
  apiKey: "AIzaSyC8-5PVa0IWCey3nhr8M8_MGy3yQzTzfI8",
  authDomain: "wa-messaging-staging.firebaseapp.com",
  projectId: "wa-messaging-staging",
  storageBucket: "wa-messaging-staging.appspot.com",
  messagingSenderId: "825238701360",
  appId: "1:825238701360:web:dfdacdda268ed209a1f4f5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const dbFS = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);


export { auth };

