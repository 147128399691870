// @mui
import { styled, alpha } from "@mui/material/styles";
import { Avatar, Box, Typography } from "@mui/material";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// components
import { CustomAvatar } from "../../../components/custom-avatar";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  return (
    <StyledRoot
      sx={{ bgcolor: `${user?.bgColor}40`, backgroundColor: "#BBFFE2" }}
    >
      {/* <CustomAvatar src={user?.photoURL} alt={user?.name} name={user?.name} /> */}
      <Avatar sx={{ bgcolor: "#0da16b", color: "white" }}>
        {user?.displayName?.slice(0, 1)}
      </Avatar>

      <Box sx={{ ml: 2, minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
          {user?.displayName || user?.phone}
        </Typography>

        <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
          {user?.role}
        </Typography>
      </Box>
    </StyledRoot>
  );
}
