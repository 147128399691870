import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client'
import {createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';


const token = localStorage.getItem('token');


export const client = new ApolloClient({
  link: createUploadLink({
    uri: "https://subscribe-staging.wl.r.appspot.com/graphql/admin",
    headers: {
      "authorization": token ? `Bearer ${token}` : ""
    }
  })
  ,
  cache: new InMemoryCache(),
  headers: {
    "authorization": token ? `Bearer ${token}` : ""
  }
});
