import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../sections/auth/Login')));
export const LoginWithEmail = Loadable(lazy(() => import('../pages/LoginWithEmail')));
export const Forget = Loadable(lazy(() => import('../pages/ForgetPassword')));
export const RegisterPage = Loadable(lazy(() => import('../pages/RegisterPage')));
export const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
export const Categories = Loadable(lazy(() => import('../pages/Campaign/Campaign')));
// export const CategoryView = Loadable(lazy(() => import('../pages/Campaign/CampaignView')));
export const CategoryView = Loadable(lazy(()=> import ('../pages/Campaign/CampaignView')))
export const Products = Loadable(lazy(() => import('../pages/products/Products')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
export const CerateCategory = Loadable(lazy(() => import('../pages/Campaign/CreateCampaign')));
export const Brand = Loadable(lazy(() => import('../pages/MessageTemplate/MessageList')));
export const NewBrand = Loadable(lazy(() => import('../pages/MessageTemplate/NewMessage')));
export const NewProduct = Loadable(lazy(() => import('../pages/products/NewProduct')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const UserList = Loadable(lazy(() => import('../pages/Voters/ClientList')));
export const NewUser = Loadable(lazy(() => import('../pages/Voters/NewClient')));
export const Customer = Loadable(lazy(() => import('../pages/Customer/CustomerList')));
export const NewCustomer = Loadable(lazy(() => import('../pages/Customer/NewCustomer')));
export const Orderlist = Loadable(lazy(() => import('../pages/Orders/OrderList')));
export const Blog = Loadable(lazy(() => import('../pages/blog/index')));
export const Role = Loadable(lazy(() => import('../pages/role/index')));
export const CreateBlog = Loadable(lazy(() => import('../pages/blog/createBlog')));
export const OrderView = Loadable(lazy(() => import('../pages/Orders/OrderView')));
export const ProductView = Loadable(lazy(() => import('../pages/products/ProductView')));
export const BrandView = Loadable(lazy(() => import('../pages/MessageTemplate/MessageView')));
export const AudienceList = Loadable(lazy(()=> import ('../pages/AudienceType/AudienceList')));
export const NewAudience = Loadable(lazy(()=> import ('../pages/AudienceType/NewAudience')))
export const Chat = Loadable(lazy(()=> import ('../pages/chat/Chat')));
export const NewTemplaate = Loadable(lazy(()=> import ('../pages/MessageTemplate/NewTemplate')));
export const Setting = Loadable(lazy(()=> import ('../pages/Setting')));
export const Wallet = Loadable(lazy(()=> import ('../pages/Wallet')));



