import { ApolloClient, gql, InMemoryCache } from '@apollo/client'



export const GET_USER = gql`
query getUser{
    getUsers {
      uid
      name
      email
      phone
      role
      status
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser($name: String!, $email:String!, $phone:String, $role:Float!,){
    createUser(input:{
      name:$name,
      email:$email,
      phone:$phone,
      role:$role
    }) {
        name
    }
  }

`;







