// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  politics: icon('ic_politics'),
  voter: icon('ic_voters'),
  audienceType : icon('material-symbols:group'),
  campaign: icon('ic_campaign'),
  wallet: icon('ic_invoice'),
  messageTemplate: icon('ic_message'),
  setting: icon('ic_setting')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.app, icon: ICONS.analytics },
      { title: 'Message Templates', path: PATH_DASHBOARD.template.list, icon: ICONS.messageTemplate },
      { title: 'Broadcast', path: PATH_DASHBOARD.broadcast.list, icon: ICONS.campaign },
      { title: 'Contacts', path: PATH_DASHBOARD.contact.list, icon: ICONS.voter },
      { title: 'Chat', path: PATH_DASHBOARD.chat.list, icon: ICONS.chat },
      // { title: 'User Management', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
      { title: 'Wallet', path: PATH_DASHBOARD.wallet, icon: ICONS.wallet },
      { title: 'Settings', path: PATH_DASHBOARD.setting, icon: ICONS.setting },



    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: '',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.user.four },
  //         { title: 'Five', path: PATH_DASHBOARD.user.five },
  //         { title: 'Six', path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
