import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
const trialSlice: any = createSlice({
  name: "trial",
  initialState: {
    businessID: null,
    plan_id: null,
    trial: false,
    first_name: "",
    last_name: "",
    email: "",
    phone: null,
    business_name: "",
    company_name: "",
    credit: 0,
    price: 0,
    waba_id :null,
    phone_number_id: "",
    whatsapp_business_id: null,
  },
  reducers: {
    updateTrial(state: any, payload: any) {
      state.businessID = payload?.payload.businessID;
      state.plan_id = payload?.payload.plan_id;
      state.trial = payload?.payload.trial;
      state.first_name = payload?.payload.first_name;
      state.last_name = payload?.payload.last_name;
      state.email = payload?.payload?.email;
      state.phone = payload?.payload.phone;
      state.business_name = payload?.payload.business_name;
      state.company_name = payload?.payload.company_name;
      state.credit = payload?.payload.credit;
      state.price = payload?.payload.price;
      state.waba_id = payload?.payload.waba_id;
      state.phone_number_id = payload?.payload.phone_number_id;
      state.whatsapp_business_id = payload?.payload.whatsapp_business_id;
    },
  },
});
export default trialSlice.reducer;
export const { updateTrial } = trialSlice.actions;
