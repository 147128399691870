import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
// components
import Iconify from "src/components/iconify";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  DocumentReference,
  collection,
  doc,
  getDoc,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { dbFS } from "src/firebase";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import * as Yup from "yup";
import * as xlsx from "xlsx";
import { DB } from "src/auth/FirebaseContext";
import { useSelector } from "react-redux";

export type createUser = {
  tag: any[];
  upload: any;
};

interface FormValuesProps extends Omit<createUser, ""> {}

const NewCustomerModel = ({ openModel, handleClose, children }: any) => {
  const [uploadContact, setUploadContact] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const collectionRef = collection(dbFS, "client");
  const { businessID } = useSelector((state: any) => state.trial);
  const q = query(collectionRef, where("businessID", "==", businessID));

  const [ClientList, loading, error] = useCollection(q, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [client, setClient] = useState<any>([]);

  useEffect(() => {
    const tempData: { id: number }[] = [];
    if (ClientList) {
      let index = 1;
      ClientList?.forEach((doc) => {
        const childData = doc.data();
        tempData.push({ ...childData, id: index++ });
      });
      setClient(tempData);
    }
  }, [ClientList]);

  const NewProductSchema = Yup.object().shape({
    upload: Yup.mixed(),
  });

  const defaultValues = {
    tag: [],
    upload: null,
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = methods;

  const values = watch();

  ///tag call
  const collectionRefs = collection(dbFS, "tag");

  const qs = query(collectionRefs, where("businessID", "==", businessID));

  const [TagList] = useCollection(qs, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [tagList, setTagList] = useState<any>([]);

  useEffect(() => {
    const tempData: { id: number }[] = [];
    if (TagList) {
      let index = 1;
      TagList?.forEach((doc) => {
        const childData = doc.data();
        tempData.push({ ...childData, id: index++ });
      });
      setTagList(tempData);
    }
  }, [TagList]);

  const [excelData, setExcelData] = useState([]);

  const readExcel = async (e: any) => {
    try {
      const file = e.target.files[0];
      const data = await file.arrayBuffer(file);
      const excelfile = xlsx.read(data);
      const excelsheet = excelfile.Sheets[excelfile.SheetNames[0]];
      const exceljson = xlsx.utils.sheet_to_json(excelsheet);
      setExcelData(exceljson as any);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  const onSubmit = async (data: FormValuesProps) => {
    try {
      const addTag = ["all"];
      const finalTag = [...addTag, ...data?.tag];
      const businessRef = doc(DB, "tag", businessID);
      const businessdocSnap = await getDoc(businessRef);

      if (!businessdocSnap?.exists()) {
        const tagRef = doc(collection(DB, "tag"), businessID);
        setDoc(tagRef, {
          businessID: businessID,
          tag: finalTag,
        });
      } else {
        const businessData = businessdocSnap?.data();
        const checkExistingTag = finalTag?.filter(
          (item: any) => !businessData?.tag.includes(item)
        );

        if (checkExistingTag.length > 0) {
          let arr: any = [];
          checkExistingTag?.map((tag: any) => {
            arr.push(tag);
          });
          const newTag = [...businessData?.tag, ...arr];

          const tagRef = doc(collection(DB, "tag"), businessID);
          setDoc(tagRef, {
            businessID: businessID,
            tag: newTag,
          });
        }
      }

      const nonExistentEntries = excelData.filter(
        (entry: any) =>
          !client.some((clientEntry: any) => clientEntry.phone === entry.Phone)
      );

      if (nonExistentEntries?.length > 0) {
        const batch = writeBatch(DB);
        nonExistentEntries.forEach(async (exData: any, index) => {
          const nycRef: DocumentReference = doc(collection(DB, "client"));
          batch.set(nycRef, {
            tag: finalTag,
            phone: exData?.Phone || "",
            name: exData.Name || "",
            status: "active",
            businessID: businessID,
            lastUpdatedAt:Math.floor((new Date().getTime() / 1000) as any),
          });
        });
        await batch.commit();
        reset();
        enqueueSnackbar("Client Uplaod Successful!");
        setUploadContact(false);
        handleClose();
      } else {
        enqueueSnackbar("Client Alredy exists !", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      handleClose();
      setUploadContact(false);
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} id="Dialog" open={openModel}>
        <>
          {!uploadContact && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "right",
                mt: 5,
                mr: 3,
                mb: { xs: -6, md: -13 },
              }}
            >
              <Button
                sx={{
                  height: 45,
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
                }}
                variant="contained"
                onClick={() => {
                  setUploadContact(!uploadContact);
                }}
                startIcon={<Iconify icon={"material-symbols:cloud-upload"} />}
              >
                Upload Contacts
              </Button>
            </Box>
          )}
        </>
        <Grid container spacing={2} sx={{ mb: 1, p: 5 }}>
          {!uploadContact && <> {children} </>}
          {uploadContact && (
            <>
              <DialogTitle id="alert-dialog-title">{"Upload"}</DialogTitle>
              <Container>
                <span>
                  here is sample file upload{" "}
                  <Button
                    href="https://firebasestorage.googleapis.com/v0/b/whatsapp-politics.appspot.com/o/Sample.xlsx?alt=media&token=d3a2a682-89cb-43cb-8329-2a71ca851b4d"
                    variant={"text"}
                    color={"warning"}
                  >
                    {" "}
                    Download{" "}
                  </Button>{" "}
                  <br /> <br />{" "}
                </span>
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Stack display="flex" direction="column" spacing={4}>
                    <Grid container>
                      <Grid item md={12} xs={12} mb={1}>
                        <>
                          <Controller
                            name="tag"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                multiple
                                freeSolo
                                onChange={(event, newValue) => {
                                  field.onChange(newValue);
                                }}
                                options={
                                  tagList?.[0]?.tag?.map(
                                    (option: any) => option
                                  ) || []
                                }
                                renderTags={(value, getTagProps) =>
                                  value.map((option: any, index) => (
                                    <Chip
                                      {...getTagProps({ index })}
                                      key={index}
                                      size="small"
                                      label={option}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    label="Add to a Group"
                                    {...params}
                                  />
                                )}
                              />
                            )}
                          />
                        </>
                      </Grid>
                      <RHFTextField
                        type="file"
                        name="upload"
                        onChange={(e) => {
                          readExcel(e);
                        }}
                        id="upload"
                      />
                    </Grid>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                    >
                      {"Upload Contacts"}
                    </LoadingButton>
                  </Stack>
                </FormProvider>
              </Container>
            </>
          )}

          <Grid
            item
            xs={12}
            md={12}
            sx={{
              margin: "auto",
              display: "flex",
              justifyContent: "right",
              p: 1,
            }}
          >
            <a
              onClick={() => {
                setUploadContact(false);
              }}
            >
              <Button id="CloseModel" sx={{ mr: 3 }} onClick={handleClose}>
                {"close"}
              </Button>
            </a>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default NewCustomerModel;
