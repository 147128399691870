import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Typography,
  Container,
  MenuItem,
  Grid,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { useSettingsContext } from "src/components/settings";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useLocation, useNavigate, useRoutes } from "react-router";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import { createUser } from "src/types/user";
import { CREATE_USER } from "src/queries/user/userQuery";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { DB } from "src/auth/FirebaseContext";
import { useCollection } from "react-firebase-hooks/firestore";
import { app, dbFS } from "src/firebase";
import { useSelector } from "react-redux";
interface FormValuesProps extends Omit<createUser, ""> {}
const TAGS_OPTION = [""];

function NewClient({ modelClose }: any) {
  // const [categoryList, setCategoryList] = useState<any>([]);

  const [tagList, setTagList] = useState<any>([]);

  const { businessID } = useSelector((state: any) => state.trial);

  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { pathname } = useLocation();

  const [newUser, { data, loading, error }] = useMutation(CREATE_USER);
 
  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required("Title is required"),
    phone: Yup.string()
      .matches(/^[0-9]*$/, "Must match the format")
      .min(10)
      .max(10)
      .required("phone number is required"),
    selectCountryCode: Yup.string().required("Please select Country code"),
  });

  const defaultValues = {
    name: "",
    phone: "",
    //category: '',
    selectCountryCode: "91",
    tag: [],
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = methods;

  const values = watch();

  const collectionRef = collection(dbFS, "tag");

  const q = query(collectionRef, where("businessID", "==", businessID));

  const [TagList] = useCollection(q, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    const tempData: { id: number }[] = [];
    if (TagList) {
      let index = 1;
      TagList?.forEach((doc) => {
        const childData = doc.data();
        tempData.push({ ...childData, id: index++ });
      });
      setTagList(tempData);
    }
  }, [TagList]);

  const onSubmit = async (data: FormValuesProps) => {
    const addTag = ["all"];
    const finalTag = [...addTag, ...data?.tag];
    const businessRef = doc(DB, "tag", businessID);
    const businessdocSnap = await getDoc(businessRef);

    if (!businessdocSnap?.exists()) {
      const tagRef = doc(collection(DB, "tag"), businessID);
      setDoc(tagRef, {
        businessID: businessID,
        tag: finalTag,
      });
    } else {
      const businessData = businessdocSnap?.data();
      const checkExistingTag = finalTag?.filter(
        (item: any) => !businessData?.tag.includes(item)
      );

      if (checkExistingTag.length > 0) {
        let arr: any = [];
        checkExistingTag?.map((tag: any) => {
          arr.push(tag);
        });
        const newTag = [...businessData?.tag, ...arr];

        const tagRef = doc(collection(DB, "tag"), businessID);
        setDoc(tagRef, {
          businessID: businessID,
          tag: newTag,
        });
      }
    }
    const phone = data.selectCountryCode + data.phone;
    const querys = query(collection(DB, "client"), where("businessID", "==", businessID), where("phone", "==", phone));

    const querySnapshot = await getDocs(querys);
   if(querySnapshot.docs.length > 0){
    enqueueSnackbar("Client Alredy exist!",{variant:'error'});
   }else {
    try {
      await addDoc(collection(DB, "client"), {
        name: data.name,
        phone: phone,
        status: "active",
        businessID,
        tag: finalTag,
        lastUpdatedAt:Math.floor((new Date().getTime() / 1000) as any),
      });
      reset();
      if (!pathname.includes("contact")) {
        modelClose();
      }
      enqueueSnackbar("Client Added Successful!");
      if (pathname.includes("contact")) {
        navigate(PATH_DASHBOARD.contact.list);
      }
    } catch (error) {
      enqueueSnackbar(error.message);
    }
   }
   
  };

  return (
    <>
      <Helmet>
        <title>Create Contact</title>
      </Helmet>
      <Stack
        direction="row"
        justifyContent="space-between"
        display="flex"
        p={5}
      >
        <Typography variant="h3" component="h1" paragraph>
          Create Contact
        </Typography>
      </Stack>
      <Container maxWidth={themeStretch ? false : "xl"}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack display="flex" direction="column">
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <RHFTextField name="name" label="Name" id="name" />
              </Grid>
              <Grid item md={4} xs={4}>
                <RHFSelect name="selectCountryCode" label="Select Country Code">
                  <MenuItem value={1}>+1</MenuItem>
                  <MenuItem value={91}>+91</MenuItem>
                </RHFSelect>
              </Grid>
              <Grid item md={8} xs={8}>
                <RHFTextField name="phone" label="Phone" id="phone" />
              </Grid>
              <Grid item md={12} xs={12}>
                <>
                  <Controller
                    name="tag"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        freeSolo
                        onChange={(event, newValue) => {
                          field.onChange(newValue);
                        }}
                        options={tagList?.[0]?.tag?.map(
                          (option: any) => option
                        ) || []}
                        renderTags={(value, getTagProps) =>
                          value.map((option: any, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              key={index}
                              size="small"
                              label={option}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            helperText={
                              values.tag.length > 0
                                ? "List groups with names separated by Enter for each group."
                                : "Type group name and press (Enter Key)"
                            }
                            label="Add to a Group"
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </>
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={isSubmitting}
              sx={{ mt: 2 }}
            >
              {"Add Contact"}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Container>
    </>
  );
}

export default NewClient;
