// @mui
import {
  Stack,
  Typography,
  Button,
  Alert,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
// auth
// layouts
import LoginLayout from "../../layouts/login";
//
import { useNavigate } from "react-router-dom";

import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "../../components/hook-form";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber
} from "firebase/auth";
import {
  doc,
  getDoc
} from "firebase/firestore";
import { DB } from "src/auth/FirebaseContext";
import { dispatch } from "src/redux/store";
import { updateTrial } from "src/redux/slices/trialSlice";
// ----------------------------------------------------------------------

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

type FormValuesProps = {
  phone: string;
  ccode: string;
  afterSubmit?: string;
};

export default function LoginWithPhone() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [changeUI, setChangeUI] = useState(false);

  const [confirmOtpData, setConfirmOtpData] = useState<ConfirmationResult>();

  const [resendOtp, setResendOtp] = useState(false);

  const [confirmOtpUI, setConfirmOtpUI] = useState(false);

  const [verifyButtonLoading, setVerifyButtonLoading] = useState(false);

  const [otp, setOtp] = useState<string>("");

  const [user, setUser] = useState<any>();

  const auth = getAuth();

  const phoneNumberRegX =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const LoginSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(phoneNumberRegX, "  Must be phone number format")
      .min(10)
      .max(10)
      .required("Phone number required"),
    ccode: Yup.string().required("Country code is required"),
  });

  const defaultValues = {
    phone: "",
    ccode: "+91",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recapcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  }, []);

  const onSubmit = async () => {
    try {
      const phoneNumber = getValues("ccode") + getValues("phone");
      // const usersQuery = query(
      //   collection(DB, "users"),
      //   where("phone", "==", phoneNumber)
      // );
      // const querySnapshot = await getDocs(usersQuery);

      // if (querySnapshot.empty) {
      //   return (
      //     enqueueSnackbar(`Phone number not register with us`, {
      //       variant: "error",
      //     }),
      //     navigate(PATH_AUTH.register)
      //   );
      // } else {
      // auth.settings.appVerificationDisabledForTesting = true;
      const appVerifier = window.recaptchaVerifier;
      const confirm = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      window.confirmationResult = confirm;
      enqueueSnackbar(`Otp send Successfully`, { variant: "success" });
      setConfirmOtpData(confirm);
      setChangeUI(true);
      setConfirmOtpUI(true);
      // setUser({ username: "ajay" });
      // }
    } catch (error) {
      console.log(error);
      setError("afterSubmit", {
        ...error,
        message: error?.message || error,
      });
    }
  };

  const confirmOTP = async () => {
    try {
      setVerifyButtonLoading(true);
      const credentials = await confirmOtpData?.confirm(otp);
      if (credentials) {
        credentials?.user.getIdToken().then(async (tokens) => {    
          if (credentials?.user) {
            const userRef = doc(DB, "users", credentials?.user?.uid);
            const docSnap = await getDoc(userRef);
            const profile = docSnap.data();
            if (profile?.businessID) {
              const businessRef = doc(DB, "business", profile?.businessID);
              const businessdocSnap = await getDoc(businessRef);
              const businessData = businessdocSnap.data();
              console.log(businessData);
              
              dispatch(
                updateTrial({
                  businessID: profile?.businessID,
                  trial: "",
                  plan_id: profile?.plan_id,
                  first_name: profile?.displayName,
                  last_name: profile?.displayName,
                  email: profile?.email,
                  phone: businessData?.phone,
                  business_name: businessData?.business_name,
                  company_name: businessData?.company_name,
                  credit:businessData?.wallet,
                  price:businessData?.price,
                  waba_id: businessData?.waba_id,
                  phone_number_id: businessData?.phone_number_id,
                  whatsapp_business_id: businessData?.whatsapp_business_id,
                })
              );
            }
          }
          enqueueSnackbar(`Login Successfully`, {
            variant: "success",
            preventDuplicate: true,
          });
        });
      }
      setVerifyButtonLoading(false);
      setConfirmOtpUI(false);
    } catch (error: any) {
      setVerifyButtonLoading(false);
      setConfirmOtpUI(false);
      enqueueSnackbar(`Invalid OTP`, { variant: "error" });
    }
  };

  const ResendOTP = async () => {
    try {
      setResendOtp(false);
      const phoneNumber = `${getValues("ccode")}${getValues("phone")}`;
      await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      ).then(() => {
        enqueueSnackbar(`OTP send success`, {
          variant: "success",
          preventDuplicate: true,
        });
      });
      setResendOtp(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`Something went wrong`, { variant: "error" });
      setResendOtp(false);
    }
  };

  return (
    <>
      <LoginLayout>
        <Typography
          variant="h3"
          sx={{ mb: 4, maxWidth: 480, textAlign: "center" }}
        >
          {"Hi, Welcome to WA Messaging"}
        </Typography>
        <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
          <Typography variant="h4" sx={{ mt: "10px" }}>
            Login
          </Typography>
        </Stack>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
            <Grid container spacing={1}>
              {!changeUI && (
                <>
                  <Grid item md={4} xs={12}>
                    <Controller
                      name="ccode"
                      control={control}
                      defaultValue={values.ccode} // Set the default value based on your variable
                      render={({ field }) => (
                        <RHFSelect {...field}>
                          <MenuItem value={"+1"}>+1</MenuItem>
                          <MenuItem value={"+91"}>+91</MenuItem>
                        </RHFSelect>
                      )}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <RHFTextField
                     onKeyDown={(e: any) => {
                      if (e.key === 'Enter'){
                        onSubmit()
                      }
                    }}
                      name="phone"
                      label="Your mobile number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    autoFocus={true}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Stack>

          <Stack alignItems="flex-end" sx={{ my: 2 }}>
            {/* <Link variant="body2" color="inherit" underline="always">
          Forgot password?
            </Link> */}
          </Stack>

          {!changeUI && (
            <>
              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{
                  bgcolor: "text.primary",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                  "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Login
              </LoadingButton>
            </>
          )}
        </FormProvider>

        {changeUI && (
          <>
            <Grid
              container
              spacing={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <Grid item md={12} xs={12}>
                <TextField
                 onKeyDown={(e: any) => {
                  if (e.key === 'Enter'){
                    confirmOTP()
                  }
                }}
                autoFocus={true}
                  name="otp"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  sx={{ minWidth: 350 }}
                  label="Enter OTP"
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Stack alignItems="flex-end">
                  <Button
                    sx={{
                      color: "black",
                      "&:hover": {
                        color: "#000",
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                      },
                    }}
                    variant="text"
                    onClick={ResendOTP}
                  >
                    Resend
                  </Button>
                </Stack>
              </Grid>

              <Grid item md={12} xs={12}>
                <LoadingButton
               
                  id="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  loading={verifyButtonLoading}
                  onClick={confirmOTP}
                >
                  Verify
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
        {!confirmOtpUI && (
          <>
            <Stack
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              sx={{ my: 2 }}
            >
              {/* <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                {"Do not have an account ?"}
              </Typography> */}
              {/* <Link
                variant="body2"
                color="inherit"
                component={RouterLink}
                to={PATH_AUTH.email}
              >
                Login with email!
              </Link> */}
            </Stack>
            {/* <Button
              sx={{
                color: "white",
                bgcolor: "green",
                "&:hover": {
                  borderColor: "lightgreen",
                  backgroundColor: "lightgreen",
                  color: "#000",
                },
              }}
              component={RouterLink}
              to={PATH_AUTH.register}
              id="submit"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {"Get started"}
            </Button> */}
          </>
        )}
      </LoginLayout>
      <div id="recapcha-container"></div>
    </>
  );
}
