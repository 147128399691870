// @mui
import { useTheme } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Alert,
  Link,
  Box,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config-global";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
import { Link as RouterLink } from "react-router-dom";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { credit } = useSelector((state: any) => state?.trial);
 
  
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const smUp = useResponsive("up", "sm");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Searchbar />
      <Box sx={{ flexGrow: 1 }} />
      {
        credit < 50 && (
          <>   {smUp && (
            <Box
              sx={{
                pr: 1,
              }}
            >
              <Alert
                id="perchasePlan"
                severity={credit === 0 ? "error": "warning"}
                sx={{ display: "flex", justifyContent: "center", width: "750px" }}
              >
                <strong id="perchaseMesssage">
                {
                credit === 0 ? " No Balance Available: Unable to send messages. Top up now!" : "  Low Credit Alert: Recharge for uninterrupted messaging! Topup Now!"
              }
                </strong>
                <Link
                  id="perchaseLink"
                  to={`/dashboard/wallet?tab=account`}
                  sx={{ color: "blue" }}
                  component={RouterLink}
                >
                  Click Here
                </Link>
              </Alert>
            </Box>
          )}</>
        )
      }
    
      {
        credit < 50 && ( 
          <>
           {!smUp && (
        <Box
          sx={{
            pt: 0.5,
            pr: 1,
          }}
        >
          <Alert
            id="perchasePlan"
            severity={credit === 0 ? "error": "warning"}
            variant="standard"
            icon={false}
            sx={{ display: "flex", justifyContent: "center", fontSize: 9 }}
          >
            <strong id="perchaseSmallMessage">
              {
                credit === 0 ? " No Balance Available: Unable to send messages. Top up now!" : "  Low Credit Alert: Recharge for uninterrupted messaging! Topup Now!"
              }
           
            </strong>
            <Link
              id="perchaseLink"
              to={`/dashboard/wallet?tab=account`}
              sx={{ color: "blue" }}
              component={RouterLink}
            >
              Click Here
            </Link>
          </Alert>
        </Box>
      )}
          </>
        )
      }
     

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}
        {/* 
        <NotificationsPopover />

        <ContactsPopover /> */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
