import { baseApi } from '../base';

const BroadCastApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createSingleMessageTemplates: build.mutation<any, any>({
      query: (data: any) => {
  
        
        return {
          method: 'POST',
          url: `/template_message`,
          body: data,
        };
      },
      invalidatesTags: ["template"],
    }),
   
  }),
});

export const {useCreateSingleMessageTemplatesMutation} = BroadCastApi;
