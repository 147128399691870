// i18n
import "./locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";
import { AuthProvider, DB } from "./auth/FirebaseContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "./ApolloClient/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor, dispatch } from "./redux/store";
import { useEffect } from "react";
import { updateTrial } from "./redux/slices/trialSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

// ----------------------------------------------------------------------

export default function App() {
  
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        const userRef = doc(DB, "users", user?.uid);
        const docSnap = await getDoc(userRef);
        const profile = docSnap.data();
        if(profile?.businessID){
          const businessRef = doc(DB, "business", profile?.businessID);
          const businessdocSnap = await getDoc(businessRef);
          const businessData = businessdocSnap.data();        
          dispatch(
            updateTrial({
              businessID: profile?.businessID,
              trial: "",
              plan_id: profile?.plan_id,
              first_name: profile?.displayName,
              last_name: profile?.displayName,
              email: profile?.email,
              phone: businessData?.phone,
              business_name: businessData?.business_name,
              company_name: businessData?.company_name,
              credit:businessData?.wallet,
              price:businessData?.price,
              waba_id: businessData?.waba_id,
              phone_number_id: businessData?.phone_number_id,
              whatsapp_business_id: businessData?.whatsapp_business_id,
            })
          );
        }
      }
    });
  }, [auth]);
  return (
    <AuthProvider>
      <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <MotionLazyContainer>
              <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <ThemeProvider>
                    <ThemeSettings>
                      <ThemeLocalization>
                        <SnackbarProvider>
                          <ApolloProvider client={client}>
                            <Router />
                          </ApolloProvider>
                        </SnackbarProvider>
                      </ThemeLocalization>
                    </ThemeSettings>
                  </ThemeProvider>
                </PersistGate>
              </ReduxProvider>
            </MotionLazyContainer>
          </BrowserRouter>
        </SettingsProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
